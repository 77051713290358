.more {
    font-size: 2.5rem;
    font-family: "Assistant";
    text-align: center;
    margin: 2% auto;
    direction: rtl;
}
.row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin:1% auto;
    justify-content: space-evenly;
    direction: rtl;
}
.description {
    font-size: 1.4rem;
    font-family: "AssistantR";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 15px auto;
    direction: rtl;
    width: 70%;
}

.what {
    background: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    margin: 25px auto;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    font-size: 1.8rem;
    font-family: "Assistant";
    text-align: center;
    margin-bottom: 10px;
    color: #333;
}

/* Responsive styles */
@media (max-width: 350px) {
    .more {
        font-size: 1.8rem;
    }
    .description {
        font-size: 1rem;
        width: 95%;
    }
    .what {
        padding: 15px;
        margin: 20px auto;
        width:80%;
    }
    .header {
        font-size: 1.4rem;
    }
}

@media (min-width: 350px) and (max-width: 450px) {
    .more {
        font-size: 2rem;
    }
    .description {
        font-size: 1.1rem;
        width: 95%;
    }
    .what {
        padding: 18px;
        width:80%;
        margin: 20px auto;
    }
    .header {
        font-size: 1.5rem;
    }
}

@media (min-width: 450px) and (max-width: 550px) {
    .more {
        font-size: 2.2rem;
    }
    .description {
        font-size: 1.2rem;
        width: 80%;
    }
    .header {
        font-size: 1.6rem;
    }
    .what {
        padding: 18px;
        width:70%;
        margin: 20px auto;
    }
}

@media (min-width: 550px) and (max-width: 650px) {
    .more {
        font-size: 2.3rem;
    }
    .description {
        font-size: 1.3rem;
        width: 75%;
    }
    .header {
        font-size: 1.7rem;
    }
    .what {
        padding: 18px;
        width:60%;
        margin: 20px auto;
    }
}

@media (min-width: 650px) and (max-width: 750px) {
    .more {
        font-size: 2.4rem;
    }
    .description {
        width: 72%;
    }
}

@media (min-width: 750px) and (max-width: 850px) {
    .more {
        font-size: 2.45rem;
    }
    .description {
        width: 70%;
    }
}

@media (min-width: 850px) and (max-width: 950px) {
    .more {
        font-size: 2.47rem;
    }
}

@media (min-width: 950px) and (max-width: 1050px) {
    .more {
        font-size: 2.48rem;
    }
}

@media (min-width: 1050px) and (max-width: 1150px) {
    .more {
        font-size: 2.49rem;
    }
}

@media (min-width: 1150px) {
    .more {
        font-size: 2.5rem;
    }
}