.title {
    font-size: 4rem;
    font-family: "Assistant";
    text-align: center;
    margin: 2% auto;
    direction: rtl;
  
}
.description{
    font-size:1.4rem;
    font-family: "AssistantR";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin:1% auto;
    direction: rtl;
    width:70%;
}
.more {
    font-size: 2.5rem;
    font-family: "Assistant";
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    
}
.quate {
    font-size: 2.5rem;
    font-family: "Assistant";
    text-align: center;
    margin: 3% auto;
    direction: rtl;
  
    background: linear-gradient(146deg, rgb(96, 73, 40) 0%, rgb(77, 59, 33) 30%, rgb(84, 62, 31) 50%, rgb(71, 47, 12) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: none;
}
.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1% auto;
    justify-content: space-evenly;
}

/* Responsive styles */
@media (max-width: 350px) {
    .title {
        font-size: 2.2rem;
        margin:3% auto;
    }
    .more,.quate  {
        font-size: 1.4rem;
        width:95%;
    }
    .row {
        flex-direction: column;
        align-items: center;
    }
    .description{
        font-size:1.1rem;
      width:90%;
    }
}

@media (min-width: 350px) and (max-width: 450px) {
    .title {
        font-size: 2.5rem;
    }
    .more,.quate  {
        font-size: 1.5rem;
        width:95%;
    }
    .description{
        font-size:1.15rem;
      width:90%;
    }
    .row {
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 450px) and (max-width: 550px) {
    .title {
        font-size: 2.8rem;
    }
    .more,.quate  {
        font-size: 2rem;
        width:90%;
    }
    .description{
        font-size:1.2rem;
      width:90%;
    }
}

@media (min-width: 550px) and (max-width: 650px) {
    .title {
        font-size: 3rem;
    }
    .more,.quate  {
        font-size: 2.2rem;
    }
    .description{
        font-size:1.25rem;
      width:90%;
    }
}

@media (min-width: 650px) and (max-width: 750px) {
    .title {
        font-size: 3.2rem;
    }
    .more,.quate  {
        font-size: 2.4rem;
    }
    .description{
        font-size:1.3rem;
      width:80%;
    }
}

@media (min-width: 750px) and (max-width: 850px) {
    .title {
        font-size: 3.4rem;
    }
    .more,.quate  {
        font-size: 2.2rem;
    }
    .description{
        font-size:1.35rem;
      width:75%;
    }
}

@media (min-width: 850px) and (max-width: 950px) {
    .title {
        font-size: 3.5rem;
    }
    .more,.quate  {
        font-size: 2.4rem;
    }
    .description{
        font-size:1.4rem;
      width:75%;
    }
}

@media (min-width: 950px) and (max-width: 1050px) {
    .title {
        font-size: 3.6rem;
    }
    .more,.quate  {
        font-size: 2.5rem;
    }
}

@media (min-width: 1050px) and (max-width: 1150px) {
    .title {
        font-size: 3.7rem;
    }
    .more,.quate  {
        font-size: 2.5rem;
    }
}

@media (min-width: 1150px) {
    .title {
        font-size: 3.8rem;
    }
    .more,.quate  {
        font-size: 2.5rem;
     
    }
}