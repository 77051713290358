.container {
    text-align: center;
    font-family: 'Assistant';
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .title {
    font-size: 2rem;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0.5rem;
  }
  
  .subtitle {
    font-size: 0.9rem;
    font-weight: 400;
    color: #2b2b2b;
    margin-bottom: 1rem;
  }
  
  .image {
    width: 100%;
    max-width: 200px;
    height: auto;

  
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .image:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 1.5rem;
    }
  
    .subtitle {
      font-size: 0.8rem;
    }
  
    .image {
      max-width: 150px;
    }
  }