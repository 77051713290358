.box{
  width:400px;
  height:auto;
  display: flex;
  flex-direction: column;
  margin: 2% auto;
}
.description{
  font-size:1.1rem;
  font-family: "AssistantR";
  text-align: center;
  margin:2% auto;
  direction:rtl;
  width:90%;
  text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
  color: #000000; /* Changed to a more readable brown */
}
.title {
  font-size: 2.5rem;
  font-family: "Assistant";
  text-align: center;
  margin: 3% auto;
  direction: rtl;

  background: linear-gradient(135deg, 
  #bba789,  /* בז' בהיר */
  #b0946f,  /* חום בהיר (tan) */
  #C19A6B,  /* חום בינוני */
  #c1a882,  /* בז' חם (champagne) */
  #E6BE8A   /* זהב עדין */
);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: none;
}
.icon{
  display: flex;
  justify-content: center;
  width:30%;
  margin:0 auto;
}
@media only screen and (max-width: 350px){
  .box{
      width:300px;
      height:auto;
      display: flex;
      flex-direction: column;
      margin:20px auto;
  }
  .description{
      font-size:1rem;
      font-family: "AssistantR";
      text-align: center;
      margin:2% auto;
      direction:rtl;
      width:90%;
      text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
 
    }
    .title{
      font-size:1.8rem;
      font-family: "Assistant";
      text-align: center;
      margin:4% auto;
      direction:rtl;
      width:95%;
      text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
   
    }
    .icon{
      display: flex;
      justify-content: center;
      width:30%;
      margin:2% auto;
    }
}
@media only screen and (min-width: 350px) and (max-width: 450px){
  .box{
      width:310px;
      height:auto;
      display: flex;
      flex-direction: column;
      margin:3% auto;
  }
  .description{
      font-size:1.1rem;
      font-family: "AssistantR";
      text-align: center;
      margin:2% auto;
      direction:rtl;
      width:90%;
      text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
     
    }
    .title{
      font-size:2rem;
      font-family: "Assistant";
      text-align: center;
      margin:3% auto;
      direction:rtl;
      width:100%;
      text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
   
    }
    .icon{
      display: flex;
      justify-content: center;
      width:30%;
      margin:2% auto;
    }
}
@media only screen and (min-width: 450px) and (max-width: 550px){
  .box{
      width:350px;
      height:auto;
      display: flex;
      flex-direction: column;
      margin:3% auto;
  }
  .description{
      font-size:1.15rem;
      font-family: "AssistantR";
      text-align: center;
      margin:2% auto;
      direction:rtl;
      width:95%;
      text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
      color: #6B4423; /* Changed to match the new description color */
    }
    .title{
      font-size:2.2rem;
      font-family: "Assistant";
      text-align: center;
      margin:3% auto;
      direction:rtl;
     
      text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
      color: #5C3D2E; /* Changed to match the new title color */
    }
    .icon{
      display: flex;
      justify-content: center;
      width:25%;
      margin:2% auto;
    }
}