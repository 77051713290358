.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  direction: rtl;
}

.shape {
  position: relative;
  width: 300px;
  height: 200px;
  background: linear-gradient(135deg, #d2b48c, #f5deb3, #f5deb3);
  border-radius: 20px 20px 70px 0;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.number {
  position: absolute;
  top: -40px;
  left: -20px;
  font-family: 'Assistant';
  font-weight: 700;
  font-size: 120px;
  color: rgba(255, 255, 255, 0.3);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  transform: rotate(-10deg);
  z-index: 0;
}

.title {
  position: relative;
  font-family: 'Assistant';
  font-weight: 700;
  font-size: 22px;
  color: #3e1c00;
  margin-bottom: 10px;
  z-index: 1;
}

.description {
  position: relative;
  font-family: 'AssistantR';
  font-size: 18px;
  color: #503600;
  z-index: 1;
}

.icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
}

/* Responsive styles */
@media screen and (max-width: 350px) {
  .shape {
    width: 240px;
    height: 160px;
    padding: 15px;
  }

  .number {
    font-size: 96px;
    top: -32px;
    left: -16px;
  }

  .title {
    font-size: 18px;
  }

  .description {
    font-size: 14px;
  }

  .icon {
    width: 40px;
    height: 40px;
  }
}

@media screen and (min-width: 350px) and (max-width: 450px) {
  .shape {
    width: 270px;
    height: 180px;
    padding: 18px;
  }

  .number {
    font-size: 108px;
    top: -36px;
    left: -18px;
  }

  .title {
    font-size: 20px;
  }

  .description {
    font-size: 16px;
  }

  .icon {
    width: 45px;
    height: 45px;
  }
}

@media screen and (min-width: 450px) and (max-width: 550px) {
  .shape {
    width: 285px;
    height: 190px;
  }

  .number {
    font-size: 114px;
    top: -38px;
    left: -19px;
  }

  .title {
    font-size: 21px;
  }

  .description {
    font-size: 17px;
  }

  .icon {
    width: 48px;
    height: 48px;
  }
}